import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { Oval } from 'react-loader-spinner';

const ProfileInfo = () => {
  const [userData, setUserData] = useState([]);
  const [state, setState] = useState(0);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [page, setPage] = useState(1);
  const [idSolarStuff, setIdSolarStuff] = useState(null);
  const [twoFaCode, setTwoFaCode] = useState('');
  const [selectedId, setSelectedId] = useState(null);
  const [scheduledPaymentAmount, setScheduledPaymentAmount] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [payingVerificationModalOpen, setPayingVerificationModalOpen] = useState(false);
  const [payingVerificationCode, setPayingVerificationCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleTwoFaCodeChange = (event) => {
    setTwoFaCode(event.target.value);
  };

  const handleScheduledPayment = async () => {
    try {
      handleVerify();
    } catch (error) {
      console.error('Error handling scheduled payment:', error);
    }
  };

  const handleVerificationCodeSubmitPay = async () => {
    try {
      setShowModal(false);
      createAndPayTask();
    } catch (error) {
      console.error('Error during verification:', error);
    }
  };

  const createAndPayTask = async () => {
    setLoading(true);
    setErrorMessage('');
    try {
      if (!idSolarStuff) {
        console.error('ID Solar-Stuff is not set');
        setLoading(false);
        return;
      }

      const taskData = {
        workerId: idSolarStuff,
        price: parseFloat(scheduledPaymentAmount) * 0.0010,
      };

      const url = `https://admin.golive.mobi/sl/sl/tasks-quick`;

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': 'Token your-token-here',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(taskData),
      });

      if (!response.ok) {
        throw new Error('Failed to create and pay task');
      }

      handleScheduledPayment2();
      setPayingVerificationModalOpen(false);
      handleClosePopup();
      setScheduledPaymentAmount('');
      setTwoFaCode('');
      fetchData();
    } catch (error) {
      console.error('Error creating and paying task:', error);
      setErrorMessage('Error creating and paying task');
    } finally {
      setLoading(false);
    }
  };

  const handleScheduledPayment2 = async () => {
    if (!selectedId) {
      console.error('No entry selected');
      return;
    }

    try {
      const response = await fetch(`https://golive.mobi/api/user/m-billing/withdraw/`, {
        method: 'POST',
        headers: {
          'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: selectedId,
          note: 'note test',
          comment: 'comment test',
          amount: parseFloat(scheduledPaymentAmount),
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      postPaymentMessage();
      handleClosePopup();
      setScheduledPaymentAmount('');
      fetchData();
    } catch (error) {
      console.error('Error scheduling payment:', error);
    }
  };

  const fetchDebugData = async (id) => {
    try {
        const response = await fetch('https://golive.mobi/api/user/status/debug/', {
            method: 'POST',
            headers: {
                'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: id
            }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return {
            solar_staff: data?.debug?.solar_staff?.payment_detail || null,
            multiacc: data?.multiacc || 'N/A', // Добавляем multiacc
        };
    } catch (error) {
        console.error('Error fetching debug data:', error);
        return { solar_staff: null, multiacc: 'N/A' };
    }
};

const fetchData = async () => {
  setLoading(true);
  try {
      // Получаем пользователей
      const response = await fetch('https://golive.mobi/api/user/m-billing/listing/', {
          method: 'POST',
          headers: {
              'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              page: page,
              page_size: '1000'
          }),
      });

      if (!response.ok) {
          throw new Error('Network response was not ok');
      }

      const users = await response.json();

      // Добавляем debug-данные
      let updatedUsers = await Promise.all(users.map(async (user) => {
          const debugData = await fetchDebugData(user.id);
          return {
              ...user,
              solar_staff: debugData.solar_staff,
              multiacc: debugData.multiacc || 'N/A',
          };
      }));

      // Обновляем данные с freelancers
      updatedUsers = await fetchFreelancers(updatedUsers);

      // Обновляем данные с проверкой email
      updatedUsers = await Promise.all(updatedUsers.map(async (user) => {
          const emailProfilesCount = user.solar_staff ? await fetchEmailProfilesCount(user.solar_staff) : 0;
          return {
              ...user,
              emailProfilesCount, // Добавляем результат проверки email
          };
      }));

      setUserData(updatedUsers); // Обновляем состояние с полным набором данных
  } catch (error) {
      console.error('Error fetching data:', error);
  } finally {
      setLoading(false);
  }
};

const fetchFreelancers = async (users) => {
  try {
      const [response1, response2] = await Promise.all([
          fetch('https://admin.golive.mobi/sl/sl/freelancers', {
              method: 'GET',
              headers: {
                  'Authorization': 'Token your-token-here',
                  'Content-Type': 'application/json',
              },
          }),
          fetch('https://admin.golive.mobi/sl/sl/freelancersRF', {
              method: 'GET',
              headers: {
                  'Authorization': 'Token your-token-here',
                  'Content-Type': 'application/json',
              },
          })
      ]);

      if (!response1.ok || !response2.ok) {
          throw new Error('Failed to fetch freelancers');
      }

      const freelancers1 = await response1.json();
      const freelancers2 = await response2.json();
      const allFreelancers = [...freelancers1.items, ...freelancers2.items];

      const updatedUsers = users.map(user => {
          const matchedFreelancer = allFreelancers.find(freelancer =>
              freelancer.email.toLowerCase() === (user.solar_staff || '').toLowerCase().trim()
          );
          return {
              ...user,
              idSolarStuff: matchedFreelancer ? matchedFreelancer.id : null,
          };
      });

      return updatedUsers; // Возвращаем обновленный массив
  } catch (error) {
      console.error('Error fetching freelancers:', error);
      return users; // Возвращаем изначальный массив, если произошла ошибка
  }
};

  const fetchEmailProfilesCount = async (email) => {
    try {
        const response = await fetch('https://golive.mobi/api/pmny/mthd/add/solar/email/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
            },
            body: JSON.stringify({ email }),
        });

        if (!response.ok) {
            console.error('Failed to fetch email profiles count');
            return 0;
        }

        const data = await response.json();
        return data.length; // Возвращаем количество ID
    } catch (error) {
        console.error('Error fetching email profiles count:', error);
        return 0;
    }
};

  useEffect(() => {
    fetchData();
  }, [state, page]);

  const handleOpenPopup = (entry) => {
    setSelectedEntry(entry);
    setIdSolarStuff(entry.idSolarStuff);
    setSelectedId(entry.id);
  };

  const handleClosePopup = () => {
    setSelectedEntry(null);
  };

  const handleVerify = () => {
    setSelectedEntry(null);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    window.location.href = 'https://admin.golive.mobi/models-new';
  };

  const handlePayingVerificationCodeSubmit2 = () => {
    setSelectedEntry(null);
    setPayingVerificationModalOpen(true);
  };

  const handleVerificationCodeSubmitPay3 = async () => {
    try {
      setShowModal(false);
      await createAndPayTask();
    } catch (error) {
      console.error('Error during verification:', error);
    }
  };

  const postPaymentMessage = async () => {
    if (!selectedId) {
      console.error('No entry selected');
      return;
    }

    const amountToSend = parseFloat(scheduledPaymentAmount) * 0.0010;
    if (isNaN(amountToSend) || amountToSend <= 0) {
      console.error('Invalid amount');
      return;
    }

    try {
      const response = await fetch('https://golive.mobi/api/payment/message/', {
        method: 'POST',
        headers: {
          'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: selectedId,
          amount: amountToSend
        })
      });

      if (!response.ok) {
        throw new Error('Failed to post payment message');
      }

      const data = await response.json();
      console.log('Payment message sent successfully:', data);
    } catch (error) {
      console.error('Error posting payment message:', error);
    }
  };

  return (
    <div>
      <Modal
        isOpen={payingVerificationModalOpen}
        onRequestClose={() => setPayingVerificationModalOpen(false)}
        contentLabel="Enter Payment Verification Code"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          content: {
            width: '200px',
            height: '200px',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            border: 'none',
            borderRadius: '10px',
          },
        }}
      >
        <div className="modal-content">
          <h4>Enter Payment Verification Code for pay</h4>
          <input
            type="text"
            value={twoFaCode}
            onChange={handleTwoFaCodeChange}
            placeholder="Payment Verification Code"
            style={{ marginBottom: '10px' }}
          />
          <div style={{ display: 'flex', gap: '10px' }}>
            <button style={{ padding: '5px 10px', borderRadius: '5px' }} onClick={handleVerificationCodeSubmitPay3}>Submit</button>
            <button style={{ padding: '5px 10px', borderRadius: '5px' }} onClick={() => setPayingVerificationModalOpen(false)}>Close</button>
          </div>
        </div>
      </Modal>
  
      <Modal
        isOpen={showModal}
        onRequestClose={handleCloseModal}
        contentLabel="Enter Verification Code"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          content: {
            width: '200px',
            height: '200px',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            border: 'none',
            borderRadius: '10px',
          },
        }}
      >
        <div className="modal-content">
          <h4>Temporarily unavailable</h4>
        </div>
      </Modal>
  
      <div>
        <button className={`button inactive-button`} onClick={() => setState(0)}>List of payments</button>
        <button className={`button inactive-button`} onClick={() => setState(1)}>List of operations</button>
      </div>
  
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <Oval color="#00BFFF" height={80} width={80} />
        </div>
      ) : (
        userData.length > 0 && (
          <>
            {state === 0 ? (
              <div>
                <h2 style={{ marginLeft: '1%' }}>List of reward models</h2>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                  <thead>
                    <tr style={{ backgroundColor: '#fffß' }}>
                      <th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)', padding: '8px' }}>Avatar</th>
                      <th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)', padding: '8px' }}>ID</th>
                      <th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)', padding: '8px' }}>Name</th>
                      <th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)', padding: '8px' }}>Email</th>
                      <th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)', padding: '8px' }}>Multi-Account</th>
                      <th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)', padding: '8px' }}>Count profiles on mellow-email</th>
                      <th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)', padding: '8px' }}>ID Solar-Stuff</th>
                      <th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)', padding: '8px' }}>Billing Status</th>
                      <th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)', padding: '8px' }}><b>Total balance</b></th>
                      <th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)', padding: '8px' }}><b>Balance from stream</b></th>
                      <th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'left', padding: '8px' }}><b>Balance from calls</b></th>
                      <th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'center', padding: '8px' }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userData.map(user => (
                      <tr key={user.id} style={{ border: '1px solid #ddd', borderRight: '1px solid rgba(0, 0, 0, 0.07)', padding: '8px' }}>
                        <td style={{
                          color: '#606C80',
                          fontFamily: 'Noto Sans Mono',
                          fontWeight: '400',
                          fontSize: '12px',
                          textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)', padding: '8px'
                        }}>
                          <img src={"https://golive.mobi" + user.avatar} alt="Avatar" style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                        </td>
                        <td style={{
                          color: '#606C80',
                          fontFamily: 'Noto Sans Mono',
                          fontWeight: '400',
                          fontSize: '12px',
                          textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)', padding: '8px'
                        }}>{user.id}</td>
                        <td style={{
                          color: '#606C80',
                          fontFamily: 'Noto Sans Mono',
                          fontWeight: '400',
                          fontSize: '12px',
                          textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)', padding: '8px'
                        }}>{user.name}</td>
                        <td style={{
                          color: '#606C80',
                          fontFamily: 'Noto Sans Mono',
                          fontWeight: '400',
                          fontSize: '12px',
                          textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)', padding: '8px'
                        }}>{user.solar_staff}</td>
                        <td style={{
    color: '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: user.multiacc === true ? 'bold' : 'normal',
    fontSize: '12px',
    textAlign: 'left',
    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
    padding: '8px',
}}>
    {user.multiacc === true ? 'Multiacc' : 'Not multiacc'}
</td>

<td style={{
    color: '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: user.emailProfilesCount > 1 ? 'bold' : 'normal', // Жирным, если больше 1
    fontSize: '12px',
    textAlign: 'left',
    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
    padding: '8px',
}}>
    {user.emailProfilesCount}
</td>

                        <td style={{
                          color: '#606C80',
                          fontFamily: 'Noto Sans Mono',
                          fontWeight: '400',
                          fontSize: '12px',
                          textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)', padding: '8px'
                        }}>{user.idSolarStuff}</td>
                        <td style={{
                          color: '#606C80',
                          fontFamily: 'Noto Sans Mono',
                          fontWeight: '400',
                          fontSize: '12px',
                          textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)', padding: '8px'
                        }}>{user.billing_status}</td>
                        <td style={{
                          color: '#606C80',
                          fontFamily: 'Noto Sans Mono',
                          fontWeight: 'bold',
                          fontSize: '12px',
                          textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)', padding: '8px'
                        }}>{user.balance}</td>
                        <td style={{
                          color: '#606C80',
                          fontFamily: 'Noto Sans Mono',
                          fontWeight: 'bold',
                          fontSize: '12px',
                          textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)', padding: '8px'
                        }}>
                          <ul>
                            {Object.entries(user.balance_inside_stream).map(([key, value]) => (
                              <li key={key}><b>{key}:</b> {value}</li>
                            ))}
                          </ul>
                          <hr />
                          <b>Total:</b> {Object.values(user.balance_inside_stream).reduce((acc, curr) => acc + curr, 0)}
                        </td>
                        <td style={{
                          color: '#606C80',
                          fontFamily: 'Noto Sans Mono',
                          fontWeight: 'bold',
                          fontSize: '12px',
                          textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)', padding: '8px'
                        }}>
                          <ul>
                            {Object.entries(user.balance_outside_stream).map(([key, value]) => (
                              <li key={key}><b>{key}:</b> {value}</li>
                            ))}
                          </ul>
                          <hr />
                          <b>Total:</b> {Object.values(user.balance_outside_stream).reduce((acc, curr) => acc + curr, 0)}
                        </td>
                        <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px', padding: '8px' }}>
                          <button className={`button inactive-button`} onClick={() => handleOpenPopup(user)}>View Details</button>
                          <button className={`button inactive-button`} onClick={() => window.open(`https://admin.golive.mobi/profile_model/${user.id}`, '_blank')}>View Profile</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <br />
              </div>
            ) : null}
  
            {state === 1 && (
              <div>
                <h2 style={{ marginLeft: '1%' }}>List of payments</h2>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                  <thead>
                    <tr style={{ backgroundColor: '#fffß' }}>
                      <th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)', padding: '8px' }}>ID</th>
                      <th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)', padding: '8px' }}>Amount</th>
                      <th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)', padding: '8px' }}>Name</th>
                      <th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)', padding: '8px' }}>Date</th>
                      <th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)', padding: '8px' }}>Balance change</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userData.map(entry => (
                      <tr key={entry.id} style={{ border: '1px solid #ddd', borderRight: '1px solid rgba(0, 0, 0, 0.07)', padding: '8px' }}>
                        <td style={{ color: '#606C80', fontFamily: 'Noto Sans Mono', fontWeight: '400', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)', padding: '8px' }}>{entry.id}</td>
                        <td style={{ color: '#606C80', fontFamily: 'Noto Sans Mono', fontWeight: '400', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)', padding: '8px' }}>{entry.amount}</td>
                        <td style={{ color: '#606C80', fontFamily: 'Noto Sans Mono', fontWeight: '400', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)', padding: '8px' }}>
                          {entry.user ? entry.user.name : 'N/A'}
                        </td>
                        <td style={{ color: '#606C80', fontFamily: 'Noto Sans Mono', fontWeight: '400', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)', padding: '8px' }}>
                          {new Date(entry.date).toLocaleString()}
                        </td>
                        <td style={{ color: '#606C80', fontFamily: 'Noto Sans Mono', fontWeight: '400', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)', padding: '8px' }}>
                          {entry.data && entry.data.before && entry.data.after ? `${entry.data.before.balance} -> ${entry.data.after.balance}` : 'N/A'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </>
        )
      )}
  
      {selectedEntry && (
        <div className="popup">
          <div style={{
            background: 'white',
            width: '500px',
            height: '300px',
            padding: '40px',
            borderRadius: '3%',
          }}>
            <button className="close-btn" onClick={handleClosePopup}>Close</button>
            <h2>Details</h2>
            <p><b>ID:</b> {selectedEntry.id}</p>
            <p><b>Name:</b> {selectedEntry.name}</p>
            <p><b>Email:</b> {selectedEntry.solar_staff}</p>
            <p><b>Balance:</b> {selectedEntry.balance}</p>
            <p><b>Amount:</b> Coins <input type="number" value={scheduledPaymentAmount} onChange={(e) => setScheduledPaymentAmount(e.target.value)} /> = ${parseFloat(scheduledPaymentAmount) * 0.0010}</p>
            <button onClick={createAndPayTask} disabled={loading}>Schedule a payment</button>
            {loading && (
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                <Oval color="#00BFFF" height={40} width={40} />
              </div>
            )}
            {errorMessage && (
              <p style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
  
  
        
};

export default ProfileInfo;
